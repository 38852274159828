import React from "react"
import ReactHtmlParser from "react-html-parser"

import "./mainheading.scss"
export default function MainHeading({
  children,
  subHeading,
  version = "v1",
  heroSection = false,
  ...props
}) {
  return (
    <>
      {subHeading && (
        <>
          {heroSection ? (
            <h1 className={`subheadingtop ${version}`}>
              {ReactHtmlParser(subHeading)}
            </h1>
          ) : (
            <h3 className={`subheadingtop ${version}`}>
              {ReactHtmlParser(subHeading)}
            </h3>
          )}
        </>
      )}
      <h2 {...props}>{ReactHtmlParser(children)}</h2>
    </>
  )
}
